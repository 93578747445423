section.burbank-container {
  position: relative;
  h1 {
    position: absolute;
    top: $top;
    color: #fff;
    z-index: 300;
    left: 15px;
    @include mobile {
      top: 55px;
    }
  }
  .brb-btn {
    display: none;
    background: $btn;
    color: #fff;
    position: absolute;
    bottom: 90px;
    left: 20px;
    z-index: 300;
    padding: 20px 40px;
    text-align: center;
    text-decoration: none;
    transition: background $speed;
    p {
      text-transform: uppercase;
      margin: 0;
    }
    &:hover {
      background: $btn-hover;
    }
  }

  // ------------------------------------------------------------------------
  .test {
    display: block;
  }
  .b-caption {
    display: none;
  }

  .burbank-capt-1 {
    display: block;
  }
  .burbank-caption {
    width: 616px;
    @include iphone {
      display: none;
    };
  }
  .burbank-1 {
    @include burbank-1;
    height: 100vh;
  }
  .burbank-5 {
    @include burbank-5;
    height: 100vh;
  }
  .burbank-6 {
    @include burbank-6;
    height: 100vh;
  }
  .burbank-7 {
    @include burbank-7;
    height: 100vh;
  }
  .burbank-8 {
    @include burbank-8;
    height: 100vh;
  }
  .burbank-9 {
    @include burbank-9;
    height: 100vh;
  }
  .burbank-10 {
    @include burbank-10;
    height: 100vh;
  }
  .burbank-11 {
    @include burbank-11;
    height: 100vh;
  }
  .burbank-12 {
    @include burbank-12;
    height: 100vh;
  }
  .burbank-13 {
    @include burbank-13;
    height: 100vh;
  }
}