section.availabilities-container {
  position: relative;
  @include availabilities-bg;
  min-height: 100vh;
  height: 100%;
  h1 {
    position: absolute;
    left: 15px;
    top: 80px;
    color: #fff;
  }
  p {
    position: absolute;
    left: 200px;
    top: 100px;
    color: #fff;
    @include ipad-portrait {
      left: 150px;
    };
    @include iphone {
      display: none;
    };
  }
  .btn-container {
    margin-top: 180px;
    height: auto;
    @include mobile {
      margin-top: 160px;
      padding-bottom: 20px;
    };
    .head-btn {
      background: $black-trans;
      height: 45px;
      color: #fff;
      font-size: 20px;
      text-align: center;
      line-height: 45px;
      margin-top: 5px;
      @include mobile {
        cursor: pointer;
      };
    }
    a {
      text-decoration: none;
      &.floor-btn {
        display: block;
        width: 100%;
        padding-top: 2px;
        margin-top: 5px;
        background: $red-trans;
        height: 45px;
        color: #fff;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        transition: background .5s;
        box-shadow: 2px 2px 4px black;
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
        &:hover {
          background: $yellow-trans;
        }
      }
    }
    a.virtual-link {
      text-align: center;
      background-color: darkred;
      color:white;
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
      max-width: 360px;
      margin-top: 3px;
      margin-left: auto;
      margin-right: auto;
    }
    a.virtual-link:hover {
      background-color:red;
    }
  }
}


.col {
  transition: height .8s;
}

.col-1 {
  @include mobile {
    height: 0;
    overflow: hidden;
  };
}

.col-2 {
  @include mobile {
    height: 0;
    overflow: hidden;
  };
}

.col-3 {
  @include mobile {
    height: 0;
    overflow: hidden;
  };
}


.col-1-active {
  height: 150px;
}

.col-2-active {
  height: 350px;
}

.col-3-active {
  height: 300px;
}
