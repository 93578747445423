// Retail width
$retail-w: 280px;
$mt: 60px;

section.amenities-container {
  padding-top: $top;
  height: 100vh;
  @include mobile {
    padding-top: 55px;
  }
  transition: background-image 1s;
  h1 {
    color: #fff;
  }
  .amenities-info {
    color: #fff;
    text-align: center;
    position: absolute;
    right: 70px;
    top: -30px;
    width: 180px;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    @include mobile {
      display: none;
    }
    @include iphone5-portrait {
      right: 15px;
      width: 165px;
    };
    .dining-info, .retail-info {
      display: none;
      text-align: left;
    }
  }
  .retail-container {
    position: absolute;
    right: 25px;
    top: 90px;
    width: $retail-w;
    @include iphone6-plus-landscape {
      top: -34px;
    }
    @include iphone5-landscape {
      top: -30px;
    }
    @include iphone6-plus-portrait {
      width: 365px;
      top: 5px;
    }
    @include iphone6-portrait {
      width: 325px;
    }
    @include iphone5-portrait {
      width: 275px;
      top: 5px;
    }
    .amen-btn {
      cursor: pointer;
      display: inline-block;
      background: $btn;
      padding: 20px 40px;
      transition: background $speed;
      @include iphone6-plus-portrait {
        padding-left: 60px;
        padding-right: 60px;
      }
      @include iphone6-portrait {
        padding: 20px 50px;
      }
      @include iphone5-portrait {
        padding: 20px 40px;
      }
      &:hover {
        background: $btn-hover;
      }
    }
    .active {
      background: $btn-hover;
      color: black;
    }
    .amen-btn:nth-child(2) {
      position: absolute;
      right: 0;
    }
    .amen-box, {
      margin-top: 20px;
      height: calc( 70vh - #{$top} - #{$mt} );
      background: #fff;
      overflow-y: scroll;
      @include mobile {
        height: 60vh;
      }
      @include iphone6-landscape {
        height: 55vh;
      }
      @include iphone5-landscape{
        height: 45vh;
      }
      @include iphone5-portrait {
        height: calc( 66vh - #{$top} - #{$mt} );        
      };
      h4 {
        padding-left: 15px;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0 15px 20px 15px;
        li {
          h5 {
            letter-spacing: 0px;
          }
        }
      }
    }
    .dining-box, .retail-box {
      display: none;
    }
    .active-box {
      display: block;
      // opacity: 1;
    }
  }
}

.dining {
  @include amenities-dining;
}

.retail {
  @include amenities-retail;
}

.red {
  background: red;
}
