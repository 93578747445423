footer {
  background-color: #444;
  text-align: center;
  font-family: "news-gothic-std";
  -webkit-font-smoothing: antialiased;
  padding: 10px 0;
  color: #ddd;

  a {
    color: white;
    text-decoration: underline;

    &:focus,
    &:hover {
      color: #ddd;
    }
  }
}