// Font Family
h1, h2, h3, p, .amen-box li, .head-btn, .floor-btn, .info li, .dining-info, .retail-info {
  font-family: "news-gothic-std";
  -webkit-font-smoothing: antialiased; //styles to make type look cleaner on chrome
}

.cnx-btn, .brb-btn > p, nav, h4, h5 {
  font-family: "expo-sans-pro-condensed";
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased; //styles to make type look cleaner on chrome
}


// Global font styling
h1 {
  font-size: 28px;
  text-shadow: 1px 1px 2px #000;
}
h2 {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 0;
}
h3 {
  font-size: 21px;
  line-height: 25px;
  font-weight: lighter;
  margin: 0;
}
h4 {
  font-size: 25px;
  line-height: 28px;
}
h5 {
  font-size: 18px;
  margin-bottom: 5px;
  text-decoration: underline;
}
p {
  font-size: 16px;
  line-height: 22px;
  // @include iphone {
  //   font-size: 12px;
  // };
}
.cnx-btn, nav {
  color: #fff;
  font-size: 18px;
  line-height: 21px;
}


// Carousel styling
.arrow-left {
  @include arrow-left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // background: red;
  z-index: 1600;
  left: 50px;
  width: 45px;
  height: 81px;
  @include iphone {
    left: 33px;
    width: 22px;
    height: 60px;
  };
}

.arrow-right {
  @include arrow-right;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1600;
  right: 50px;
  width: 45px;
  height: 81px;
  @include iphone {
    right: 33px;
    width: 22px;
    height: 60px;
  };
}

// Carousel Captions
.caption-bg {
  padding: 4px 0 0 15px;
  position: absolute;
  left: 0;
  bottom: 30px;
  height: 30px;
  z-index: 300;
  @include caption-bg;
}

// Dom styling
body {
  position: relative;
  background-color:black;
}

// Removes dottel outline for IE
a {
  outline: 0 !important;
}

a:focus {
  outline-style: solid!important;
  outline-width: 2px!important;
  -webkit-transition: none!important;
  transition: none!important;
  outline-color: white;
}
