section.contact-container {
  @include contact-bg;
  @include iphone {
    height: 100%;
  };
  padding-top: $top;
  height: 100vh;
  .row {
    h1 {
      color: white;
    }
    h4 {
      color: $global-nav-black;
    }
  }
  .row {
    .map-cover {
      text-align: center;
      transition: opacity $speed;
      opacity: 0;
      margin-left: 15px;
      width: calc(100% - 30px);
      height: 100%;
      background: $nav-bg;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 300;
      h2 {
        margin: 0;
        text-transform: uppercase;
        color: #fff;
      }
    }
    .view-label {
      opacity: 1;
    }
    .enter-map {
      display: none;
    }
    #map {
      @include iphone {
        height: 350px;
      };
      height: 500px;
      width: 100%;
      background: gray;
    }
    .contact-box {
      // width: 30.5%;
	    min-width: 400px;
      background: #fff;
      @include ipad-landscape {
        width: 40%;
      };
      @include mobile {
        min-width: 300px;
        width: calc(100vw - 30px);
        margin-left: 15px;
        margin-bottom: 15px;
      };
	  .intercon-logo {
		width: 100%;
		margin-top: 15px;
		margin-bottom: 30px;
		}
	  .lpcwest-wrapper {
		  height: 50px;
		  margin-bottom: 17px;
		  margin-top: 10px;
		}
	  .lpcwest-logo {
		  width: 90%;
		  max-width: 240px;
		}
      .cbre-logo{
        @include cbre-logo;
        @include ipad-portrait {
          width: 50%;
        };
        @include iphone {
          width: 40%;
        };
        width: 60%;
        height: 50px;
        margin: 15px 0 12px 0;
      }
      .lpc-logo{
        @include lpc-logo;
        width: 100%;
        height: 60px;
        margin-bottom: 10px;
      }
      p {
        margin-right: 20px;
        color: $global-nav-black;
      }
      a, a p {
        color: $red;
        text-decoration: underline;
        &:hover {
          color: $yellow;
          text-decoration: none;
        }
      }
      .management-title {
        margin-top: 24px;
      }
      p.contact-title {
        //padding-bottom: 12px;
      }
    }
    .contact-margin {
      padding: 20px 0 20px 20px;
      margin-top: 15px;
      i {
        position: relative;
        top: 5px;
        font-size: 30px;
      }
      p {
        display: inline-block;
        padding-left: 8px;
      }
    }
  }
}
