// Color Variables -------------------------------------------------------------
  // Colors
  $red: #CC404D;
  $blue: #00708C;
  $yellow: #F4B72B;
  $gray: #C3C3C3;
  $black-trans: rgba(0, 0, 0, 0.8);
  $red-trans: #CC404D;
  $gray-trans: rgba( 74, 74, 74, 0.95);
  $yellow-trans: rgba(244, 183, 43, 1);
  $global-nav-black: rgba(74, 74, 74, 0.75);

  // Nav Variables
  $nav-height: 65px;
  $nav-bg: $gray-trans;
  $nav-active: #FF5254;

  // Buttons
  $btn: $red;
  $btn-hover: $yellow;
  // $btn-border: $red;

  // Hero Page
  $xline-bg: $black-trans;


  // Siteplan Page
  $info: $blue;
  $map-bg: $blue;

// Global ----------------------------------------------------------------------


// Transition speed
  $speed: .7s;

// Page top padding
  $top: 85px;
