nav.navbar {
  position: absolute;
  width: 100%;
  margin: 0;
  border-radius: 0;
  background: $nav-bg;
  border: none;
  height: $nav-height;
  z-index: 500;
  border: none !important;
  .navbar-header {
    .navbar-toggle {
      margin-top: 15px;
      border-color: transparent;
    }
    .navbar-brand {
      @include logo;
      @include mobile {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      };
      width: 200px;
      margin-left : 5px;
      margin-top: 10px;
      height: 45px;
    }
  }
  .navbar-collapse {
    border: none;
    box-shadow: none;
    height: 420px;
    ul.navbar-nav {
      position: relative;
      @include mobile {
        height: 100vh;
        top: 8px;
        margin-top: 0;
        background: $nav-bg;
      };
      @include ipad {
        margin: 0 -30px;
        background: $nav-bg;
      };
      @include ipad-landscape {
        margin-left: 0px;
      };
      li {
        a {
          padding-top: 24px;
          color: #fff;
          text-align: center;
          &:hover, &:focus {
            color: $nav-active;
            background: transparent;
          }
        }
      }
    }
  }
  .navbar-collapse.in {
    overflow: visible;
  }
}

nav.navbar-fixed-top {
  position: fixed;
}

nav.navbar-fixed-top-forced {
  position: fixed !important;
  top: 0 !important;
}

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: none;
}

#main-nav {
  .navbar-nav {
    li.active {
      a {
        color: $nav-active;
        background: transparent;
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 1110px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
}
