section.tenants-container {
  position: relative;
  // padding-top: $top;
  height: 100%;
  @include iphone {
    @include tenants;
    height: 100vh;
    img {
      display: none;
    }
  };
  h1 {
    position: absolute;
    top: 80px;
    left: 15px;
    color: #fff;
  }
  .tenant-map {
    width: 100%;
  }
}
