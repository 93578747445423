// Hero
@mixin hero-bg {
  background-image: url('../img/hero-bg-06-02-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// About Carousel
@mixin about-1 {
  background-image: url('../img/about-mezzanine-deck-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-2 {
  // background-image: url('../img/333-n-glenoaks-05-26-16.jpg');
  background-image: url('../img/landscaped-courtyards-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-3 {
  // background-image: url('../img/303-n-glenoaks-05-26-16.jpg');
  background-image: url('../img/stairway-access-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-4 {
  // background-image: url('../img/stairway-access-05-26-16.jpg');
  background-image: url('../img/interior-shot-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin about-5 {
  background-image: url('../img/work-and-play-05-26-16.jpg');
  // background-image: url('../img/colorful-murals-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-6 {
  // background-image: url('../img/terraced-landscape-05-26-16.jpg');
  background-image: url('../img/terraces-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-7 {
  // background-image: url('../img/landscaped-courtyards-05-26-16.jpg');
  background-image: url('../img/interior-shot-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-8 {
  // background-image: url('../img/breezeway-05-26-16.jpg');
  background-image: url('../img/terraced-landscape-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin about-9 {
  // background-image: url('../img/terraces-05-26-16.jpg');
  background-image: url('../img/work-bars-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-10 {
  // background-image: url('../img/work-and-play-05-26-16.jpg');
  background-image: url('../img/captivating-lighting-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-11 {
  background-image: url('../img/300-entrance.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-12 {
  background-image: url('../img/300-lobby.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-13 {
  background-image: url('../img/300-conference.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin about-14 {
  background-image: url('../img/300-lounge.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

// Siteplan
@mixin sitemap {
  background-image: url('../img/sitemap-06-02-16.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

// Availabilities
@mixin availabilities-bg {
  background-image: url('../img/availabilities-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

// Burbank
@mixin burbank-1 {
  background-image: url('../img/burbank-dtn-bur-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin burbank-5 {
  background-image: url('../img/burbank-granville-cafe-06-02-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin burbank-6 {
  background-image: url('../img/burbank-new-town-center-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin burbank-7 {
  background-image: url('../img/burbank-five-guys-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@mixin burbank-8 {
  background-image: url('../img/burbank-moores-deli-06-02-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin burbank-9 {
  background-image: url('../img/burbank-commonwealth-06-02-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@mixin burbank-10 {
  background-image: url('../img/burbank-amc-30-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin burbank-11 {
  background-image: url('../img/burbank-tonys-darts-away-05-26-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin burbank-12 {
  background-image: url('../img/burbank-brewyard-beer-06-02-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin burbank-13 {
  background-image: url('../img/burbank-blue-room-06-02-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// towncenter
@mixin towncenter-1 {
  background-image: url('../img/TCA_v05_A02_(1).jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin towncenter-5 {
  background-image: url('../img/TCA_v06_A02.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin towncenter-6 {
  background-image: url('../img/btc-aerial.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin towncenter-7 {
  background-image: url('../img/burbank-town-center-4.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@mixin towncenter-8 {
  background-image: url('../img/btc-apartment.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin towncenter-9 {
  background-image: url('../img/btc-podium.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

@mixin towncenter-10 {
  background-image: url('../img/btc-hotel.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin towncenter-11 {
  background-image: url('../img/btc-plaza.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin towncenter-12 {
  background-image: url('../img/TCA_BTC_v04_B02.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin towncenter-13 {
  background-image: url('../img/Food_Court.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// Amenities
@mixin amenities-dining {
  background-image: url('../img/amenities-dining-06-03-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  @include iphone {
    background-image: url('../img/amenities-blank-06-02-16.jpg');
  };
}

@mixin amenities-retail {
  background-image: url('../img/amenities-retail-06-03-16.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  @include iphone {
    background-image: url('../img/amenities-blank-06-02-16.jpg');
  };
}

// Tenants
@mixin tenants {
  background-image: url('../img/tenants-mobile.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

// Contact
@mixin contact-bg {
  background-image: url('../img/contact.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
