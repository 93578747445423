section.siteplan-container {
  color: #fff;
  .info {
    padding-top: calc(#{$nav-height} + 10px);
    background: $info;
    height: 100vh;
    @include mobile {
      padding-top: $nav-height;
      height: auto;
      // padding-bottom: 30px;
    };
    h3 {
      padding: 5px 0;
    }
    p {
      margin-bottom: 15px;
    }
    ul {
      -webkit-padding-start: 0px;
      list-style-type: disc;
      li {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px;
        list-style-position: outside;
        list-style: none;
      }
      .copy-flow {
      @include mobile {
        display: none;
        }
      }
    }

  }
  .siteplan {
    padding-top: calc(#{$nav-height} + 10px);
    background: #fff;
    height: 100vh;
    background: $map-bg;
    @include mobile {
      padding-top: 0;
      height: 500px;
    };
    @include iphone {
      padding-top: 0;
      height: 260px;
    };
    .map {
      @include sitemap;
      width: 100%;
      height: 100%;
    }
  }
}
