section.about-container {
  position: relative;
  h1 {
    position: absolute;
    top: $top;
    color: #fff;
    z-index: 300;
    left: 15px;
    @include mobile {
      top: 55px;
    }
  }
  .about-caption {
    width: 616px;
    @include iphone {
      display: none;
    };
  }
  .a-caption {
    display: none;
  }

  .about-capt-1 {
    display: block;
  }
  .img-1 {
    z-index: 50;
    @include about-1;
    height: 100vh;
  }
  .img-2 {
    z-index: 50;
    @include about-2;
    height: 100vh;
  }
  .img-3 {
    z-index: 50;
    @include about-3;
    height: 100vh;
  }
  .img-4 {
    z-index: 50;
    @include about-4;
    height: 100vh;
  }
  .img-5 {
    z-index: 50;
    @include about-5;
    height: 100vh;
  }
  .img-6 {
    z-index: 50;
    @include about-6;
    height: 100vh;
  }
  .img-7 {
    z-index: 50;
    @include about-7;
    height: 100vh;
  }
  .img-8 {
    z-index: 50;
    @include about-8;
    height: 100vh;
  }
  .img-9 {
    z-index: 50;
    @include about-9;
    height: 100vh;
  }
  .img-10 {
    z-index: 50;
    @include about-10;
    height: 100vh;
  }
  .img-11 {
    z-index: 50;
    @include about-11;
    height: 100vh;
  }
  .img-12 {
    z-index: 50;
    @include about-12;
    height: 100vh;
  }
  .img-13 {
    z-index: 50;
    @include about-13;
    height: 100vh;
  }
  .img-14 {
    z-index: 50;
    @include about-14;
    height: 100vh;
  }
}
