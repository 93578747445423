section.hero-container {
  height: 100vh;
  @include hero-bg;
  .logo {
    @include logo-black;
    @include iphone5-landscape {
      width: 200px;
    };
    width: 300px;
    height: 80px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .xline-container {
    background: $xline-bg;
    width: 300px;
    height: 300px;
    padding: 15px;
    position: absolute;
    top: 20vh;
    right: 12.5vw;
    width: 415px;
    @include ipad-portrait {
      width: 55%;
    };
    @include ipad-landscape {
      width: 41.1%;
    };
    @include iphone {
      width: 90%;
      left: 20px;
    };
    @include iphone-landscape {
      top: 60px;
      width: 60%;
      left: 245px;
    };
    @include iphone5-landscape {
      top: 100px;
    };
    .xline {
      @include xline;
      height: 65px;
      @include iphone6-portrait {
        height: 55px;
      };
      @include iphone5-portrait {
        height: 45px;
      };
      @include iphone5-landscape {
        height: 51px;
      };
    }
    p {
      color: #fff;
      margin-top: 10px;
      padding-left: 5px;
    }
    a {
      text-decoration: none;
      &:hover {
        @include non-mobile {
          & ~ .xline {
            @include xline-hover;
          }
        };
      }
      &.learn-btn {
        position: absolute;
        right: 30%;
        bottom: 0px;
        width: 50px;
        height: 80px;
        color: #fff;
        text-align: center;
        font-size: 15px;
        opacity: .5;
        transition: opacity $speed;
        @include iphone-landscape {
          bottom: 0;
        };
        &:hover {
          opacity: 1;
        }
        .down-arrow {
          @include down-arrow;
          height: 45px;
        }
      }
    }
  }
}
