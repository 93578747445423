@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
 $responsive-unitless: $responsive / ($responsive - $responsive + 1);
 $dimension: if(unit($responsive) == 'vh', 'height', 'width');
 $min-breakpoint: $min / $responsive-unitless * 100;
 @media (max-#{$dimension}: #{$min-breakpoint}) {
   font-size: $min;
 }
 @if $max {
   $max-breakpoint: $max / $responsive-unitless * 100;
   @media (min-#{$dimension}: #{$max-breakpoint}) {
     font-size: $max;
   }
 }
 @if $fallback {
   font-size: $fallback;
 }
 font-size: $responsive;
}

@mixin non-mobile {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: 1600px) and (max-width: 1920px) {
    @content;
  }
}
@mixin ipad {
  @media (min-width: 736px) and (max-width: 1024px) {
    @content;
  }
}
@mixin ipad-landscape {
  @media (width: 1024px) {
    @content;
  }
}
@mixin ipad-portrait {
  @media (min-width: 736px) and (max-width: 768px) {
    @content;
  }
}
@mixin mobile {
  @media (min-width: 0px) and (max-width: 768px) {
    @content;
  }
}
@mixin iphone {
  @media (min-width: 0px) and (max-width: 736px) {
    @content;
  }
}
@mixin iphone-landscape {
  @media (min-width: 568px) and (max-width: 736px) {
    @content;
  }
}
@mixin iphone6-plus-landscape {
  @media (min-width: 667px) and (max-width: 736px) {
    @content;
  }
}
@mixin iphone6-landscape {
  @media (min-width: 568px) and (max-width: 667px) {
    @content;
  }
}
@mixin iphone5-landscape {
  @media (min-width: 414px) and (max-width: 568px) {
    @content;
  }
}
@mixin iphone-portrait {
  @media (min-width: 0px) and (max-width: 414px) {
    @content;
  }
}
@mixin iphone6-plus-portrait {
  @media (min-width: 375px) and (max-width: 414px) {
    @content;
  }
}
@mixin iphone6-portrait {
  @media (min-width: 320px) and (max-width: 375px) {
    @content;
  }
}
@mixin iphone5-portrait {
  @media (min-width: 0px) and (max-width: 320px) {
    @content;
  }
}
