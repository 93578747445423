section.page-container {
  margin: 100px auto 50px;
  max-width: 900px;
  min-width: 200px;
  padding: 0 20px;
  
  p,
  h1, h2, h3, h4, ul, ol {
    color: #ddd;
  }
}